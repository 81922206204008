<template>
	<div class="container">
		<!-- <a-tutorial-video videoSrc='https://www.youtube.com/embed/tgbNymZ7vqY'></a-tutorial-video> -->
		<h3>Prihláška</h3>
		<div class="row justify-content-center">
			<div v-for="(c, i) in productTypes" :key="i" class="col-lg-3 product" @click="selectType(c)">
				<router-link :to="{ name: 'Order', params: { type: c.code } }"></router-link>
				<b-card class="z-clickable py-3" :class="c.disabled ? 'disabled' : ''">
					<h5 class="text-center mb-0">{{ c.name }}</h5>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	components: {
		//	'a-tutorial-video': () => import('@/plugins/app/order/components/a-tutorial-video.vue')
	},

	mounted() {
		this._loadProductTypes()
	},

	data() {
		return {
			productTypes: []
		}
	},

	methods: {
		async selectType(c) {
			await this.$store.commit('order/reseted', true)
			if (!c.disabled) {
				this.$router.push({ name: 'Order', params: { type: c.code } })
			}
		},

		async _loadProductTypes() {
			try {
				const response = await axios.get('v1/producttypes')
				this.productTypes = response.data.data
			} catch (error) {
				console.error(error)
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.disabled
	opacity: .6
	cursor: not-allowed
.z-clickable
	border-radius: 10px
	border: 2px solid #fff

	&:hover
		border-color: rgba(#76AD63, 0.1)
		background-color: rgba(#76AD63, 0.1)
.product
	margin-bottom: 15px
@media (max-width: 991px)
	.product
		margin-bottom: 30px
</style>
